function setupAutoExpand(textarea: HTMLTextAreaElement | null): void {
    if (textarea) {
      textarea.addEventListener("input", function (this: HTMLTextAreaElement, event: Event) {
        const parentNode = textarea?.parentNode as HTMLElement | null;
  
        if (parentNode && parentNode.dataset) {
          parentNode.dataset.value = this.value;
        }
      });
    }
  }

  export {
    setupAutoExpand
  };
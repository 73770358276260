/* eslint-disable import/prefer-default-export */
const setResponsiveTable = (table: Element): void => {
  const head = table.querySelector('thead');
  const body = table.querySelector('tbody');
  const foot = table.querySelector('tfoot');

  if (head) {
    const headers: string[] = [];
    const ths = head.querySelectorAll('th');
    ths.forEach((h) => {
      if (!h.classList.contains('table__cell--self-explanatory')) {
        headers.push(h.innerHTML); // Because innerText inherits stuff like text-transform
      } else {
        headers.push(''); // If header is self explanatory we do not add it
      }
    });

    const rowContainers: HTMLTableSectionElement[] = [];
    // Table elements that possibly contains <tr>'s
    if (body) {
      rowContainers.push(body);
    }
    if (foot) {
      rowContainers.push(foot);
    }

    rowContainers.forEach((rc) => {
      const rows = rc.querySelectorAll('tr');
      rows.forEach((tr) => {
        const cells = tr.querySelectorAll('td, th');
        cells.forEach((cell, i) => {
          if (headers[i]) {
            // if header is self explanatory we skip (value of array index is '' which is falsy)
            cell.setAttribute('data-label', headers[i]);
          }
        });
      });
    });
  }
};

function runDemotableButtons(buttons: NodeListOf<Element>, rows: NodeListOf<HTMLTableRowElement>) {
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      if (button.id === 'demo-button--attention') {
        rows.forEach((row) => {
          if (row.id === 'table__row--flash-attention') {
            row.classList.remove('table__row--flash-transparent');
            row.classList.add('table__row--flash-attention');
            changeBackgroundColor(rows);
          }
        });
      }
      if (button.id === 'demo-button--confirm') {
        rows.forEach((row) => {
          if (row.id === 'table__row--flash-confirm') {
            row.classList.remove('table__row--flash-transparent');
            row.classList.add('table__row--flash-confirm');
            changeBackgroundColor(rows);
          }
        });
      }
      if (button.id === 'demo-button--warning') {
        rows.forEach((row) => {
          if (row.id === 'table__row--flash-warning') {
            row.classList.remove('table__row--flash-transparent');
            row.classList.add('table__row--flash-warning');
            changeBackgroundColor(rows);
          }
        });
      }
    });
  });
}


function changeBackgroundColor(rows: NodeListOf<HTMLTableRowElement>) {
  setTimeout(() => {
    rows.forEach((row: HTMLTableRowElement) => {
      if (row.classList.contains('table__row--flash-attention')) {
        row.classList.remove('table__row--flash-attention')
      }
      if (row.classList.contains('table__row--flash-confirm')) {
        row.classList.remove('table__row--flash-confirm')
      }
      if (row.classList.contains('table__row--flash-warning')) {
        row.classList.remove('table__row--flash-warning')
      }
      row.classList.add('table__row--flash-transparent');
    });
  }, 1000);
}

export {
  setResponsiveTable,
  runDemotableButtons,
  changeBackgroundColor
};
